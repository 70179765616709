import React , { useState }from 'react';
import logo from '../assets/img/silver_logo1.png'
import { getToken, getEntity }  from '../services/TABService';
import base64 from 'react-native-base64'

export default function Login({ setEntity, setHash, setToken}){
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [err, setErr] = useState("");
    
    const handleSubmit = async e => {
        e.preventDefault();
        setLogin();
      }


    async function setLogin(){
        console.log("set login ");
        if(user === null || user === 'undefined' || user === ''){
             console.log("user not define...");
             setErr("Utilizador de Prenchimento Obrigatório !");
             return;
         }

         if(password === null || password === 'undefined' || password === ''){
             console.log("user not define...");
             setErr("Código de Acesso Preenchimento Obrigatório !");
             return;
         }

        const sc = password.split("-")[0];
        getToken(user,password).then(response =>{
            if(response != null && response.data.code !== 400){
                const hash = response.data.hash;
                setHash(hash);
                getEntity(hash, sc).then(r => {
                    console.log("Utilizador Válido !!");
                    setEntity(r.data);
                    setToken(sc);
                    setErr("");
                    const session = JSON.stringify({code : sc, hash : hash });
                    sessionStorage.setItem("session",base64.encode(session));
                }).catch(err =>{
                    setErr("Credenciais Não correspondem a uma conta Ativa !");
                });
            }else{
                setErr("Utilizador ou Código de Acesso Inválido !!!");
                console.error("Utilizador ou Código de Acesso Inválido !!!");
            }
        }).catch(error => {
            console.log("Errror -->"+error)
            console.error(error)
            setErr("Utilizador ou Código de Acesso Inválida !!!");
        }); 
    }

    return (<div className='loginpage'>
    <div className='logo'>   
        <img src={logo} alt='logoTipo'></img>
    </div>
    <div className='loginform'>
        <h3>Autenticação</h3> 
        <div className='error'><p>{err}</p></div>
    <form onSubmit={handleSubmit}>
    <div className='login'> 
            <input className='inputlogin' type='text' autoComplete="off" placeholder="Utilizador" value={user} autoFocus 
            onChange={(e) => setUser(e.target.value)}></input>
            <div style={{padding:"8px"}}></div>
            <input className='inputlogin' type='password' autoComplete="off" placeholder='Código de Acesso' value={password} 
             onChange={(e) => setPassword(e.target.value)}  ></input>
            
            <button className='btn btn-dark'>Entrar</button>
            
    </div>
    </form>
    </div>
    <div className='footer'>
        <div className="text-center p-3 dashboardfooter text-dark"> © 2023 Copyright | 
            <a target="_blank" rel="noreferrer" className="text-dark" href="http://silverspheres.pt/"> silverspheres@2023 (V20230712)</a>
        </div>
    </div>
    </div>);
}
