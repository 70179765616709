import React from "react";
import 'reactjs-popup/dist/index.css';
import { getMealList } from "../services/TABService";
import moment from "moment";
import 'moment/locale/pt'
import Popup from "./PopUp";
moment.locale('pt')

export default class Calendar extends React.Component {
  constructor(props) {
      super(props);
      this.hash = props.hash;
      this.code = props.code;
  };

  state = {
    showCalendarTable: true,
    showMonthTable: false,
    dateObject: moment(),
    allmonths: moment.months(),
    showYearNav: false,
    selectedDay: null, 
    isOpen : false,
  }
  
  meals = [] ;
  updateMeals = false;
  weekdayshort = moment.weekdaysShort();

  componentDidMount() {
    if(this.updateMeals === false){
      this.getMealsList(this.hash, this.code, this.firstDateOfMonth(), this.lastDateOfMonth());
      this.updateMeals = true;
    }else{
      this.updateMeals = false;
    }
  }

  componentDidUpdate(){
    if(this.updateMeals === false){
      this.getMealsList(this.hash, this.code, this.firstDateOfMonth(), this.lastDateOfMonth());
      this.updateMeals = true;
    }else{
      this.updateMeals = false;
    }
  }

  daysInMonth = () => {
    return this.state.dateObject.daysInMonth();
  };
  year = () => {
    return this.state.dateObject.format("Y");
  };
  currentDay = () => {
    return this.state.dateObject.format("D");
  };

  currentMonth = () =>{
    const d = new Date();
    return d.getMonth() + 1;
  }

  firstDayOfMonth = () => {
    let dateObject = this.state.dateObject;
    let firstDay = moment(dateObject)
      .startOf("month")
      .format("d"); // Day of week 0...1..5...6
    return firstDay;
  };

  firstDateOfMonth = () => {
    let dateObject = this.state.dateObject;
    let lastDay = moment(dateObject)
      .startOf("month")
      .format("YYYY-MM-DD");
    return lastDay;
  };

  lastDateOfMonth = () => {
    let dateObject = this.state.dateObject;
    let lastDay = moment(dateObject)
      .endOf("month")
      .format("YYYY-MM-DD");
    return lastDay;
  };

  month = () => {
    return this.state.dateObject.format("MMMM");
  };

  getMonthNumber = () => {
    return this.state.dateObject.format("MM");
  };

  showMonth = (e, month) => {
    this.setState({
      showMonthTable: !this.state.showMonthTable,
      showCalendarTable: !this.state.showCalendarTable
    });
  };
  setMonth = month => {
    let monthNo = this.state.allmonths.indexOf(month);
    let dateObject = Object.assign({}, this.state.dateObject);
    dateObject = moment(dateObject).set("month", monthNo);
    this.setState({
      dateObject: dateObject,
      showMonthTable: !this.state.showMonthTable,
      showCalendarTable: !this.state.showCalendarTable
    });
  };
  MonthList = props => {
    let months = [];
    props.data.map(data => (
      months.push(
        <td
          key={data}
          className="calendar-month"
          onClick={e => {
            this.setMonth(data);
          }}
        >
          <span>{data}</span>
        </td>
      )
    ));
    let rows = [];
    let cells = [];

    months.forEach((row, i) => {
      if (i % 3 !== 0 || i === 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
    });
    rows.push(cells);
    let monthlist = rows.map((d, i) => {
      return <tr key={Math.random()}>{d}</tr>;
    });

    return (
      <table className="calendar-month">
        <thead>
          <tr key={Math.random()}>
            <th colSpan="4">Select a Month</th>
          </tr>
        </thead>
        <tbody>{monthlist}</tbody>
      </table>
    );
  };
  showYearEditor = () => {
    this.setState({
      showYearNav: true,
      showCalendarTable: !this.state.showCalendarTable
    });
  };

  onPrev = () => {
    let curr = "";
    if (this.state.showMonthTable === true) {
      curr = "year";
    } else {
      curr = "month";
    }
    this.setState({
      dateObject: this.state.dateObject.subtract(1, curr)
    });
  };
  onNext = () => {
    let curr = "";
    if (this.state.showMonthTable === true) {
      curr = "year";
    } else {
      curr = "month";
    }
    this.setState({
      dateObject: this.state.dateObject.add(1, curr)
    });
  };
  setYear = year => {
    // alert(year)
    let dateObject = Object.assign({}, this.state.dateObject);
    dateObject = moment(dateObject).set("year", year);
    this.setState({
      dateObject: dateObject,
      showMonthTable: !this.state.showMonthTable,
      showYearNav: !this.state.showYearNav,
    });
  };

  onYearChange = e => {
    this.setYear(e.target.value);
  };
  getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDateDate = moment(stopDate);
    while (currentDate <= stopDateDate) {
      dateArray.push(moment(currentDate).format("YYYY"));
      currentDate = moment(currentDate).add(1, "year");
    }
    return dateArray;
  }
  YearTable = props => {
    let months = [];
    let nextten = moment()
      .set("year", props)
      .add("year", 12)
      .format("Y");

    let tenyear = this.getDates(props, nextten);

    tenyear.map(data => (
      months.push(
        <td
          key={data}
          className="calendar-month"
          onClick={e => {
            this.setYear(data);
          }}
        >
          <span>{data}</span>
        </td>
      )
    ));
    let rows = [];
    let cells = [];

    months.forEach((row, i) => {
      if (i % 3 !== 0 || i === 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
    });
    rows.push(cells);
    let yearlist = rows.map((d, i) => {
      return <tr>{d}</tr>;
    });

    return (
      <table className="calendar-month">
        <thead>
          <tr>
            <th colSpan="4">Select a Yeah</th>
          </tr>
        </thead>
        <tbody>{yearlist}</tbody>
      </table>
    );
  };
  onDayClick = (e, d) => {
    this.setState(
      {
        selectedDay: d,
        isOpen : true
      }, () =>
      console.log(d)
    );
  };

  togglePopup = () =>{
    //this.state.isOpen = !this.state.isOpen;
    this.setState({isOpen : !this.state.isOpen });
  }

 async getMealsList(hash, code, startDate, endDate) {
    await getMealList(hash, code, startDate, endDate).then(response => {
      return response.data;
    }).then(value => {
        console.log(value);
        if(value){
          this.setState(this.meals = value);
        }
    }).catch(err =>{
        console.log(err.message);
    });
  }

  verifyIfExistDay = (day, info) =>{
    if(info.length == 0){
      return false;
    }
    let dayInfo = { have: false, status : 0 , legend : ""};
    for(let value of info){
      if(value.date == day){
        dayInfo.have = true;
        dayInfo.status = value.status;
        dayInfo.legend = value.statusInfo;
        break;
      }
    }
    return dayInfo;
  }
  

  render() {
    let weekdayshortname = this.weekdayshort.map(day => {
      return <th key={day}>{day}</th>;
    });
    let blanks = [];
    for (let i = 0; i < this.firstDayOfMonth(); i++) {
      blanks.push(<td key={Math.random()} className="calendar-day empty">{""}</td>);
    }

    let daysInMonth = [];
    let month = this.state.dateObject.format("M");
    let legend = [];
    let alreadyInclude=[];
    for (let d = 1; d <= this.daysInMonth(); d++) {
      let currentDay = (d == this.currentDay()) && (month == this.currentMonth()) ? "today" : "";
      let calendarDate =this.year() + "-" +this.getMonthNumber()+"-"+ (d <= 9 ? "0"+ d : d ) ;
      let found = this.verifyIfExistDay(calendarDate, this.meals);
      let eventOn =  found.have ? "eventOn_"+found.status : "" ;
      if(found.have && !alreadyInclude.includes(found.legend)){
        legend.push(<td key={Math.random()} className={`eventOn_`+found.status}>
          <span>{found.legend}</span>
          </td>);
          alreadyInclude.push(found.legend);
      }
      daysInMonth.push(
        <td key={Math.random()} className={`calendar-day ${currentDay} ${eventOn}`}>
          <div key={Math.random()} className="calendar-day" onClick={e => {
              this.onDayClick(e, d);
            }} >
          <p>
            {d}
            </p>
          </div>
        </td>
      );
    }

    var totalSlots = [...blanks, ...daysInMonth];
    let rows = [];
    let cells = [];

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
      if (i === totalSlots.length - 1) {
        rows.push(cells);
      }
    });

    let daysinmonth = rows.map((d, i) => {
      return <tr key={Math.random()}>{d}</tr>;
    });

    let dateSelected = this.year() + "-" +this.getMonthNumber()+"-"+ (this.state.selectedDay <= 9 ? "0"+ this.state.selectedDay : this.state.selectedDay ) ;

    return (
      <div className="container tail-datetime-calendar">
        <div className="calendar-navi">
          <span
            onClick={e => {
              this.onPrev();
            }}
            className="calendar-button button-prev"  /> 
          {!this.state.showMonthTable && !this.state.showYearEditor && (
            <span
              onClick={e => {
                this.showMonth();
              }}
              className="calendar-label"
            >
              {this.month()},
            </span>
          )}
          <span
            className="calendar-label"
            onClick={e => {
              this.showYearEditor();
            }}
          >
            {this.year()}
          </span>

          <span
            onClick={e => {
              this.onNext();
            }}
            className="calendar-button button-next"
         />
        </div>
        <div className="calendar-date">
          {this.state.showYearNav && <this.YearTable key={Math.random()} props={this.year()} />}
          {this.state.showMonthTable && (
            <this.MonthList key={Math.random()} data={moment.months()} />
          )}
        </div>

        {this.state.showCalendarTable && (
          <div className="calendar-date">
            <table className="calendar-day">
              <thead>
                <tr>{weekdayshortname}</tr>
              </thead>
              <tbody>{daysinmonth}</tbody>
            </table>
          </div>
        )}

      {legend && (
          <div className="calendar-date">
            <table style={{textAlign:"center"}}>
              <tfoot><tr>{legend}</tr></tfoot>
            </table>
          </div>
        )}

        
      
        {this.state.isOpen ? <div>  <Popup key={Math.random()}
            date={dateSelected} hash={this.hash} code={this.code} closePopup={this.togglePopup.bind()}
          />
            </div>
          : null}
      </div>
    );
  }
  
}
