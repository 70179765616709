import React, {Component } from "react";
import base64 from 'react-native-base64'
import {getEntity} from "../services/TABService";
import avatar from '../assets/img/avatar.png' ;

export default class Entity extends Component {

    constructor(props) {
        super(props);
        this.state = {
          hash : props.hash,
          code : props.code,
          entity : ""
         }
    };

    componentDidMount(){
        const session = sessionStorage.getItem("session")
        let hash = this.state.hash;
        let code = this.state.code;
         if(session != null || session !== undefined){
          var jsonobj = JSON.parse(base64.decode(session));
          hash = jsonobj.hash;
          code = jsonobj.code;
         }
        this.getEntityData(hash, code);
      }
    
    formatValue = (value) =>{
        const formatter = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return formatter.format(value).replace(".",",");
      }
    
    logout = () =>{
        sessionStorage.removeItem('session');
        window.location.href = '/';
    }


    reload = ()=>{
        this.getEntityData(this.state.hash, this.state.code);
    }

    getEntityData = (hash, code)=>{
        getEntity(hash, code).then(response => {
          this.setState({entity : response.data });
        }).catch(err => {
          console.log(err);
        });
      }

    getImage = () => {
        if(this.state.entity.photo){
         return `data:image/jpg;base64,${this.state.entity.photo}`;
        }
        return avatar;
      }
      
    getTitle = ()=>{
        return "Bem-vindo ao Portal do TAB";
    }

    render() {
      return (
        <>
        <div className="dashboard" >
            <div className="header">
                <h5 className="p-2">{this.getTitle()}</h5>
                <button className="btn btn-warning logout" onClick={this.logout}>Sair</button>
                <img src={this.getImage()} className="rounded-circle shadow-5 avatar"
                style={{width: "80px"}} alt="Avatar" />
            </div>
            <div className="p-2">
                <button className="btn btn-success" onClick={e => { this.reload() }} >Atualizar</button>
            </div>
            <div className="content">
             <div className="row">
                <div className="col-sm dashboardText">
                    <p>Nome : {this.state.entity.name}</p>
                    <p>Número Aluno : {this.state.entity.numclient}</p>
                    <p>Número Processo : {this.state.entity.numprocess}</p>
                    <p>Número Identificação : {this.state.entity.nif}</p>
                    
                </div>
                <div className="col-sm dashboardText">
                    <p>E-mail : {this.state.entity.email}</p>
                    <p>País : {this.state.entity.country}</p>
                    <p>Saldo : <span style={{fontSize: "20px", color:"green", fontWeight:"bold"}}>{this.formatValue(this.state.entity.balance)}€</span></p>
                    <p>Escola : {this.state.entity.companyFullName}</p>
                </div>
             </div> 
            </div>
            </div>
      </>
        );
    }
};