import React, {Component } from "react";
import MealSchedule from "./MealSchedule"

export default class Reserve extends Component {
    constructor(props) {
        super(props);
        this.state = {
          hash : props.hash,
          code : props.code,
         }
      };


    render() {
      return (
          <>
            <div className="schedule">
               <h3>Reservas</h3>
                <MealSchedule key={Math.random()} hash={this.state.hash} code={this.state.code} />
             </div>
          </>
        );
    }
};