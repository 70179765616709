import React from "react";
import { getMealsAvailable, updateMealsAvailable } from "../services/TABService";
import MealMenu from "./MealMenu";

class Popup extends React.Component {

    hash = this.props.hash ;
    code = this.props.code; 
    state = {
        mealDate : this.props.date,
        meals : undefined,
        loading: true ,
        error : undefined,
        sucess : undefined,
        mealsSelected : []
    }
    loaded = false;

    componentDidMount() {
        if(this.loaded === false){
            this.getMealsAvailable(this.hash, this.code, this.state.mealDate);
            this.loaded = true;
        }else{
            this.loaded = false;
        }
    }

    async getMealsAvailable(hash, code, date) {
        await getMealsAvailable(hash, code, date).then(response => {
          return response.data;
        }).then(value => {
            this.setState(this.state.meals = value);
        }).catch(err =>{
            console.log(err)
            this.setState({error : "Erro : "+err.message});
        });
    }

    async updateMealsAvailable(hash, code, reserve, cancel) {
        await updateMealsAvailable(hash, code,this.state.mealDate, reserve, cancel).then(response => {
            return response.data;
        }).then(value => {
            if(value &&  value.status && value.status !== 200){
                this.setState({ error : value.result.message, sucess : null});        
            }else{
                this.setState({ error : null, sucess : value.message });
                this.getMealsAvailable(this.hash, this.code, this.state.mealDate);
            }
        }).catch(err =>{
            console.log(err)
            this.setState({error : err.result, sucess : null});
        });
    }

    reserveMeal =() => {
        if(this.state.mealsSelected.length > 0) {
            console.log("Reservar !!");
            let reserve = [];
            this.state.mealsSelected.map((value)=> (reserve.push(value)));
            this.updateMealsAvailable(this.hash, this.code, JSON.stringify(reserve), undefined);
            reserve = [];
            this.setState({mealsSelected : []});
        }else{
            console.log("Nada a Fazer !!");
            this.setState({error : "Tem de Selecionar !!", sucess : null});
        }
    }

    cancelMeal =(data) => {
        if(data){
            let cancel = [];
            cancel.push(data);
            this.updateMealsAvailable(this.hash, this.code, undefined, JSON.stringify(cancel) );
        }
    }

    checkBox = (data) => {
        const [checked, setChecked] = React.useState(false);                
        let handleChange = (e)=>{
            if(e.target.checked){
                this.state.mealsSelected.push(data.value);
            }else{
                this.state.mealsSelected.pop(data.value);
            }
            setChecked(!checked);
        }
        return (
          <div>
                <label>
                    <input id="checkbox" type="checkbox" onChange={e => { handleChange(e) } } />
                </label>
            </div>
        );
      };

      // show link to consult mealMenu
      mealDayMenu = (data) => {
        const [showPopup, setShowPopup] = React.useState(false);
        const togglePopup = () => {
            setShowPopup(!showPopup);
          };
        return (
            <>
            <div>
                <a href="#" onClick={togglePopup}>
                    Consultar ...
                </a>
                <MealMenu key={Math.random()} show={showPopup} closePopup={togglePopup} data={data} />
            </div>
            </>
        );
      };


    mealsAvailableList = ()=>{  
        if(this.state.meals){
            let cssStyle = this.state.meals.available.length > 5 ? "table-wrapper-scroll-y my-custom-scrollbar" : "";
            let mealsList = this.state.meals.available.map((d, i) => {
                return <><tr key={Math.random()} className="mealsInfo">
                    <React.Fragment>
                        <td>{d.mealtypedescription}</td>
                        <td>{d.description}</td>
                        <td>{d.value}</td>
                        <td><><this.mealDayMenu key={Math.random()} value={d} /></> </td>
                        <td><>
                             <this.checkBox key={Math.random()} value={d} />
                        </></td>
                    </React.Fragment>
                    </tr></>;
                    
            });
            return (
                <React.Fragment>
                <div className={cssStyle}>
                <table className="table table-bordered table-striped mb-0">
                    <thead>
                    <tr key={Math.random()} style={{fontSize:14}}>
                        <th>Tipo</th>
                        <th>Nome</th>
                        <th>Valor</th>
                        <th>Ementa</th>
                        <th>Agendar</th>
                    </tr>
                    </thead>
                    <tbody>{mealsList}</tbody>
                </table>
                </div>
                <div>
                <button style={{fontSize:14}} className="btn btn-primary" onClick={e => { this.reserveMeal() }} >
                            Agendar
                </button>
                </div>
            </React.Fragment>
            );    
        }else{
            return (
                <div key={Math.random()}>Loading...</div>
            );
        }
    }

    mealsReservedList = ()=>{ 
        if(this.state.meals){
            let cssStyle = this.state.meals.reserved.length > 5 ? "table-wrapper-scroll-y my-custom-scrollbar" : "";
            let mealsList = this.state.meals.reserved.map((d, i) => {
                let disable = !d.cancancel ;
                return <><tr key={Math.random()} className="mealsInfo">
                    <React.Fragment>
                        <td>{d.mealtypedescription}</td>
                        <td>{d.description}</td>
                        <td>{d.value}</td>
                        <td>{d.status}</td>
                        <td><>
                             <button disabled={disable} 
                                style={{fontSize:12}} className="btn btn-warning" onClick={e => { this.cancelMeal(d) ; }} >
                                Cancelar
                            </button>
                        </></td>
                    </React.Fragment>
                    </tr></>;
            });
            return (
                <React.Fragment>
                <div className={cssStyle}>
                <table className = "table">
                    <thead>
                    <tr key={Math.random()} style={{fontSize:14, color:"ffff"}}>
                        <th>Tipo</th>
                        <th>Nome</th>
                        <th>Valor</th>
                        <th>Estado</th>
                        <th>Operação</th>
                    </tr>
                    </thead>
                    <tbody>{mealsList}</tbody>

                </table>
                </div>
                </React.Fragment>
            );   
        }else{
            return (
               <div key={Math.random()}>Loading...</div>
            );
        }
    }

    render() {
      return (
        <div className='popup'>
          <div className='popup_inner'>
            <div className="popupButtons">
                <button type="button" className="btn btn-secondary btn-lg" onClick={this.props.closePopup}>X</button>
            </div>
            <div className="popupTitle">
                <h2 style={{textAlign:"center"}}>Dia : {this.state.mealDate}</h2>
            </div>
            <div>
                {this.state.error && <><p  key="err" className="alert alert-warning" role="alert">{this.state.error}</p></>}
                {this.state.sucess && <><p key="suce" className="alert alert-primary" role="alert">{this.state.sucess}</p></>}
            </div>
            
            <div>
                <div className="popupSubTitles">
                    <p>Disponiveis Para Reserva</p>
                </div>
                <div key={Math.random()} className="popupTable">
                    {this.mealsAvailableList()}
                </div>
            </div>
            &nbsp;
            <div>
                <div className="popupSubTitles">
                    <p>Agendadas</p>
                </div>
                <div key={Math.random()} className="popupTable">
                    {this.mealsReservedList()}
                </div>
            </div>
          </div>
        </div>
      );
    }
  }

export default Popup