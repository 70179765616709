import React, {Component } from "react";
import base64 from 'react-native-base64'
import { getDocumentList } from "../services/TABService";

export default class Document extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hash : props.hash,
      code : props.code,
      documents: []
     }
};

componentDidMount(){
    const session = sessionStorage.getItem("session")
    let hash = this.state.hash;
    let code = this.state.code;
     if(session != null || session !== undefined){
      var jsonobj = JSON.parse(base64.decode(session));
      hash = jsonobj.hash;
      code = jsonobj.code;
     }
     this.getList(hash, code);
  }

  getList =(hash, code) =>{
    getDocumentList(hash, code).then(response => {
      this.setState({documents : response.data });
    });
  }

  chargeStyle = (value) => {
    if(value.startsWith("Carregamento")){
      return {
        color :"green",
      };
    }
  }
  
  formatValue = (value) =>{
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formatter.format(value).replace(".",",");
  }
    render() {
      return (
        <>
        <div className="documents">
        <h3>Documentos</h3>
        <ul className="separator"> {this.state.documents && this.state.documents.map((d) =>{
            return <li key={d.id}> <div>
                <p className="dc">{d.description} </p>
                <p className="dcvalue" style={this.chargeStyle(d.description)}>{this.formatValue(d.value)}€</p>
               <p className="dcs"> Referencia : {d.reference} </p>
               <p className="dcs"> Serie : {d.serie} </p>
                <p className="dcs"> Data : {d.date} </p>
              </div> 
            </li>;
          } )}
          </ul>
          </div>
          </>
        );
    }
};
