import React, {Component } from "react";
import { getNotificationList } from "../services/TABService";
import base64 from 'react-native-base64'


export default class Notification extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hash : props.hash,
      code : props.code,
      notification : []
     }
  };

  componentDidMount(){
    const session = sessionStorage.getItem("session")
    let hash = this.state.hash;
    let code = this.state.code;
     if(session != null || session !== undefined){
      var jsonobj = JSON.parse(base64.decode(session));
      hash = jsonobj.hash;
      code = jsonobj.code;
     }
     this.getNotificationList(hash, code);
  }

  getNotificationList =(hash, code) =>{
    try{
      getNotificationList(hash, code).then(response => {
        if(response != null){
          this.setState({notification : response.data});
        }
      });
    }catch(Error){
      console.log(this.state);
    }
  }

  render() {
      return (
          <>
          <div className="documents">
          <h3>Notificações</h3>
          <ul className="separator"> {this.state.notification && this.state.notification.length > 0 && this.state.notification.map((n) =>{
            return <li key={Math.random()}> 
              <div>
                <p className="dc">{n.type}</p>
                <p className="dcs">Estado : {n.status}</p>
                <p className="dcs">Data : {n.date}</p>
                <div style={{whiteSpace: "pre-wrap"}} className="dcs">{n.data}</div>
              </div> 
              </li>;
          } )}
          </ul>
          <ul className="separator"> 
              {this.state.notification.length === 0 && 
              <div>
                <p className="dc">Não Exitem Notificações</p>
              </div> 
            }
          </ul>
          </div>
          </>
        );
    }
};