import axios from "axios";
import { getClientURL, getXAPI } from "./Utils";

export async function  getToken(number, token){
    console.log("Get getToken Call ....");
    const sc = token.split("-")[0];
    const xis_api = getXAPI(sc);
    try{
        const simpleHeader = {
            'Authorization': 'Bearer my-token',
            "content-type" : "application/json",
            "X-API-KEY" : xis_api
        };
        const url = getClientURL(sc)+"tokengenerate";
        const axiosIntance = axios.create({
            method : "GET",
            baseURL : url ,
            params : {'number' : number , 'token' : token } ,
            headers : simpleHeader
        }); 
        return await axiosIntance.call();
    }catch (error){
        console.error("Error on get value in API ");
        console.error(error);
        return null;
    }
}

export async function  getEntity(hash, token){
    console.log("getEntity CAll ...");
    const xis_api = getXAPI(token);
    try{
        const simpleHeader = {
            "content-type" : "application/json",
            "X-API-KEY" : xis_api 
        };
        const data = { "hash" : hash , "token" : token };
        const url = getClientURL(token);
        return await axios.post(url +"entitylist",JSON.stringify(data) ,{ headers : simpleHeader });
    }catch (error){
        console.error("Error on get value in API ");
        console.error(error);
        return null;
    }
}

export async function  getDocumentList(hash, token){
    console.log("getDocumentList CAll ...");
    const xis_api = getXAPI(token);
    try{
        const simpleHeader = {
            "content-type" : "application/json",
            "X-API-KEY" : xis_api
        };
        const data = { "hash" : hash , "token" : token};
        const url = getClientURL(token) + "documentlist";
        return await axios.post(url,JSON.stringify(data) ,{ headers : simpleHeader });
    }catch (error){
        console.error("Error on get value in API ");
        console.error(error);
        return null;
    }
}

export async function  getAccessList(hash, token){
    console.log("getAcessList CAll ...");
    const xis_api = getXAPI(token);
    try{
        const simpleHeader = {
            "content-type" : "application/json",
            "X-API-KEY" : xis_api 
        };
        const data = { "hash" : hash,  "token" : token, "limit" : 15 , "offset" : 0};
        const url = getClientURL(token) + "accesslist";
        return await axios.post(url,JSON.stringify(data) ,{ headers : simpleHeader });
    }catch (error){
        console.error("Error on get value in API ");
        console.error(error);
        return null;
    }
}


export async function  getMealList(hash, token, startDate, endDate){
    const xis_api = getXAPI(token);
    try{
        const simpleHeader = {
            "content-type" : "application/json",
            "X-API-KEY" : xis_api 
        };
        const data = { "hash" : hash,  "token" : token, "startDate" : startDate , "endDate" : endDate};
        const url = getClientURL(token) + "mealslist";
        return await axios.post(url,JSON.stringify(data) ,{ headers : simpleHeader });
    }catch (error){
        console.error("Error on get value in API ");
        console.error(error);
        return null;
    }
}

export async function  getMealsAvailable(hash, token, date){
    const xis_api = getXAPI(token);
    try{
        const simpleHeader = {
            "content-type" : "application/json",
            "X-API-KEY" : xis_api 
        };
        const data = { "hash" : hash,  "token" : token, "date" : date};
        const url = getClientURL(token) + "mealsavailable";
        return await axios.post(url,JSON.stringify(data) ,{ headers : simpleHeader });
    }catch (error){
        console.error("Error on get value in API ");
        console.error(error);
        return null;
    }
}

export async function  updateMealsAvailable(hash, token, dt, reserve, cancel){
    const xis_api = getXAPI(token);
    try{
        const simpleHeader = {
            "content-type" : "application/json",
            "X-API-KEY" : xis_api 
        };
        const data = { "hash" : hash,  "token" : token, date : dt, reserve, "cancel" : cancel};
        const url = getClientURL(token) + "mealsupdate";
        return await axios.post(url,JSON.stringify(data) ,{ headers : simpleHeader });
    }catch (error){ 
        console.log(error.response);
        return error.response;
    }   
}


export async function  getStatisticsData(hash, token){
    const xis_api = getXAPI(token);
    try{
        const simpleHeader = {
            "content-type" : "application/json",
            "X-API-KEY" : xis_api 
        };
        const data = { "hash" : hash,  "token" : token};
        const url = getClientURL(token) + "statisticsdata";
        return await axios.post(url,JSON.stringify(data) ,{ headers : simpleHeader });
    }catch (error){
        console.error("Error on get value in API ");
        console.error(error);
        return null;
    }
}


export async function  getNotificationList(hash, token){
    console.log("getNotification CAll ...");
    const xis_api = getXAPI(token);
    try{
        const simpleHeader = {
            "content-type" : "application/json",
            "X-API-KEY" : xis_api 
        };
        const data = { "hash" : hash,  "token" : token };
        const url = getClientURL(token) + "notificationlist";
        return await axios.post(url,JSON.stringify(data) ,{ headers : simpleHeader });
    }catch (error){
        console.error("Error on get value in API ");
        console.error(error);
        return null;
    }
}
