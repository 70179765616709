import base64 from "react-native-base64";
import data from '../data/data.json';

export function getClientURL(token){
    return process.env.REACT_APP_API_URL ;
}

export function getXAPI(token){
    const x_api = process.env.REACT_APP_X_API_KEY ;
    let newAPI= null;
    data.forEach(config =>{
        if(config.code.startsWith(token)){
            const val = config.info.user+":"+config.info.password+":MOBILE:"+token+":"+getDate();
            newAPI = base64.encode(val);
            return;
        }
    });
    return newAPI != null ? newAPI: x_api;
}

function getDate(){
    return formatDate(new Date())+ " 235959";
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    return [year, month, day].join('-');
}