
// verify if exist menu form data, if not show description.
function getMealDayMenu (data){
  if(data.value.mealdaymenu){
    return data.value.mealdaymenu;
  }
  console.log("meal Day Menu not found ...");
  return data.value.description;
}

// component to Show Meal Menu, in calendar.
const MealMenuPopup = ({ show, closePopup, data }) => {
  const divStyle = {
    zIndex: 1000
  };
  if(show){
    return(
      <>
         <div className="popup" style={{divStyle}} >
          <div className="popup_inner popup_innerMenu">
            <div className="popupButtons">
                <button type="button" className="btn btn-secondary btn-lg" onClick={closePopup}>X</button>
            </div>
            
            <div className="popupTitle">
                <h2 style={{textAlign:"center"}}>Ementa</h2>
            </div>
            <div>
                <p></p>
                <p style={{fontSize:"16px", marginLeft:"12px"}}>{getMealDayMenu(data)}</p>
            </div>
          </div>
          </div>
      </>
    );
  }else{
    return null;
  }
};

export default MealMenuPopup;