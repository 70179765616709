import React, { useState } from 'react';
import Login from "./components/Login";
import Dashboard from './components/Dashboard.js'
import MasterPage from './components/MasterPage';

export default function App(){
  const [entity, setEntity] = useState();
  const [hash, setHash] = useState();
  const [token, setToken] = useState();
  

  const session = sessionStorage.getItem("session");
  if(session === null){
    if(!entity || entity === null) {
      return <Login setEntity={setEntity} setHash={setHash} setToken={setToken}/>
    }
  }

  // return (
  //     <div className='container dashboard'>
  //       <Dashboard key={hash} hash={hash} code={token} session ={session} />
  //   </div>
  // ) ;

  return (
    <div className='container'>
      <MasterPage key={hash} hash={hash} code={token} session ={session} />
  </div>
) ;


}
