import React, {Component, Fragment } from "react";
import base64 from 'react-native-base64'
import Home from "./Home";
import Document from "./Document";
import Navigation from './Navigation';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Access from "./Acessos";
import Reserve from "./Reserve"
import Entity from "./Entity";
import Notification from "./Notication";

export default class MasterPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
          entity : "",
          hash : props.hash,
          code : props.code,
          documents: [],
          access: [] ,
          tabIndex : 0,
         }
         this.value = new Date();
    };

    componentDidMount(){
        const session = sessionStorage.getItem("session")
        let hash = this.state.hash;
        let code = this.state.code;
         if(session != null || session !== undefined){
          var jsonobj = JSON.parse(base64.decode(session));
          hash = jsonobj.hash;
          code = jsonobj.code;
         }
        this.setState({hash : hash, code : code});
      }

    render () {
        return(
        <Fragment>
        <div>
            <div>
                <Entity key={Math.random()} hash={this.state.hash} code={this.state.code}/>
            </div>
            <div className="row" >
            <BrowserRouter className="row">
                    <div className="col-2">
                        <Navigation />
                    </div>
                    <div className="col-md">
                        <Routes>
                        <Route path="/" element ={<Home key={Math.random()} hash={this.state.hash} code={this.state.code} />} exact/>
                        <Route path="/document" element={<Document  key={Math.random()} hash={this.state.hash} code={this.state.code}/>} />
                        <Route path="/access" element={<Access key={Math.random()} hash={this.state.hash} code={this.state.code} />} />
                        <Route path="/reserve" element={<Reserve key={Math.random()} hash={this.state.hash} code={this.state.code} />} />
                        <Route path="/notification" element={<Notification key={Math.random()} hash={this.state.hash} code={this.state.code} />} />
                        </Routes>
                    </div>
               
            </BrowserRouter>
            </div>
            <footer className="bg-light text-center text-lg-start">
                <div className="text-center p-3 dashboardfooter"> © 2023 Copyright | 
                    <a target="_blank" rel="noreferrer" className="text-dark" href="http://silverspheres.pt/"> silverspheres@2023 (V20230712)</a>
                </div>
                </footer>
        </div>
        </Fragment>
    )}
}