import React, { Fragment } from "react";
import {  
  Tooltip,
  PieChart, 
  Pie,
  Cell} from 'recharts';
  import { getStatisticsData } from "../services/TABService";

class Home extends React.Component {
  
  constructor(props) {
    super(props);
    this.hash = props.hash;
    this.code = props.code;
  };

  state = {
        mostConsumed : [],
        lastRecord:[],
        mostBuyData : [],
        accountData : []
  }


    componentDidMount(){
      this.getStatisticsData(this.hash, this.code);
    }

    updateLabel = () => {
      return "Atualize os Dados com o Botão Atualizar";
    }

    async getStatisticsData(hash, code) {
      await getStatisticsData(hash, code).then(response => {
        return response.data;
      }).then(value => {
        this.setState({mostBuyData : value.topSales});
        this.setState({accountData : value.movements});
      }).catch(err =>{
          console.log(err)
      });
  }


    topSellingProduct = () =>{
      let topList = this.state.mostBuyData.map((d, i) => {
        return <><tr key={Math.random()}>
            <React.Fragment>
                <td>{i+1}</td>
                <td>{d.count}</td>
                <td>{d.name}</td>
                <td>{d.value} €</td>
            </React.Fragment>
            </tr></>;
      });

      return <React.Fragment>
            <div>
            <table className = "table table-striped table-hover">
                    <thead style={{backgroundColor:"black", color:"white"}}>
                    <tr key={Math.random()} style={{fontSize:14}}>
                        <th scope="col">#</th>
                        <th scope="col">Quantidade</th>
                        <th scope="col">Nome</th>
                        <th scope="col">Valor</th>
                    </tr>
                    </thead>
                    <tbody>{topList}</tbody>
                </table>
            </div>
      </React.Fragment>
    }

    RenderCustomizedLabelSales = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
      const RADIAN = Math.PI / 180;
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
      return <>
          <text x={x} y={y} style={{wordBreak :"break-all"}} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {`${this.state.mostBuyData[index].count}`}
        </text>
        </>
    };

    topSellingProductPie = () =>{
      const COLORS = ['#5ADA5A','#6EEE6E', '#78F878', '#82FF82', '#8CFF8C', '#AAFFAA','#A2FFA0'];
      return <>
            <PieChart key={Math.random()} width={400} height={400}>
            <Pie key={Math.random()} dataKey="count" data={this.state.mostBuyData} 
              labelLine={false} label={this.RenderCustomizedLabelSales} >
                {this.state.mostBuyData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </>
    }

    lastMovement = () =>{
      const COLORS = ['#29a3a3','#2eb8b8', '#33cccc', '#47d1d1', '#5cd6d6', '#70dbdb','#85e0e0','#99e6e6'];
      return <>
            <PieChart key={Math.random()} width={400} height={400}>
              <Pie key={Math.random()}
                dataKey="value"
                data={this.state.accountData}
                label={this.RenderCustomizedLabel}
                fill="#8884d8"
                labelLine={false}
              >
                {this.state.accountData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
            </Pie>
              <Tooltip />
          </PieChart>
      </> ;
    }

    RenderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
      const RADIAN = Math.PI / 180;
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
      return (
        <text x={x} y={y} style={{wordBreak :"break-all"}} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {`${this.state.accountData[index].value} €`}
        </text>
      );
    };

    render(){
        return <Fragment>
            <div className="home">
              <div className="card">
                <h5 className="card-header">Produtos Mais Consumidos(Últimos 3 Meses) </h5>
                <div className="card-body">
                  {this.topSellingProduct()}
                  <p className="card-text"><small className="text-muted">{this.updateLabel()}</small></p>
                </div>
              </div>

              <>&nbsp;</>

              <div className="row">
                <div className="col-sm-6" >
                  <div className="card">
                      <div className="card-body">
                        <h5 className="card-title">Produtos Mais Consumidos(Últimos 3 Meses)</h5>
                         {this.topSellingProductPie()}
                        <p className="card-text"><small className="text-muted">{this.updateLabel()}</small></p>
                      </div>
                    </div>
                </div>
                <div  className="col-sm-6">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Resumo Mensal</h5>
                      {this.lastMovement()}
                      <p className="card-text"><small className="text-muted">{this.updateLabel()}</small></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
    }
}

export default Home;
