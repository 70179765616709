import React from 'react';
 
import { NavLink } from 'react-router-dom';
 
const Navigation = () => {
    return (
       <div className='nav-bar'>
         <ul>
          <li><NavLink className="nav-link xis-nav-links xisicons ui-xis-home-icon-xis-home xis-home" to="/"><p>Início</p> </NavLink> </li>
          <li><NavLink className="nav-link xis-nav-links xisicons ui-xis-home-icon-config_4 xis-document" to="/document"><p>Documentos</p></NavLink> </li>
          <li><NavLink className="nav-link xis-nav-links xisicons ui-xis-home-icon-report xis-access" to="/access" > <p>Acessos</p> </NavLink></li>
          <li><NavLink className="nav-link xis-nav-links xisicons ui-xis-home-icon-xis-calendar xis-reserve" to="/reserve"><p>Reservas</p></NavLink></li>
          <li><NavLink className="nav-link xis-nav-links xisicons ui-xis-home-icon-notification xis-notification" to="/notification"><p>Notificações</p></NavLink></li>
          </ul>
       </div>
    );
}
 
export default Navigation;