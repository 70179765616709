import React, {Component } from "react";
import { getAccessList } from "../services/TABService";
import base64 from 'react-native-base64'


export default class Access extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hash : props.hash,
      code : props.code,
      access : []
     }
  };

  componentDidMount(){
    const session = sessionStorage.getItem("session")
    let hash = this.state.hash;
    let code = this.state.code;
     if(session != null || session !== undefined){
      var jsonobj = JSON.parse(base64.decode(session));
      hash = jsonobj.hash;
      code = jsonobj.code;
     }
     this.getAccessList(hash, code);
  }

  getAccessList =(hash, code) =>{
    getAccessList(hash, code).then(response => {
      this.setState({access : response.data});
    });
  }

  delayStyle = (value) => {
    if(value === 'Atrasado'){
      return {
        color :"#F17119",
        fontSize:"16px"
      };
    }
  }

  render() {
      return (
          <>
          <div className="access">
          <h3>Acessos</h3>
          <ul className="separator"> {this.state.access && this.state.access.map((a) =>{
            return <li key={Math.random()}> 
              <div>
                <p className="dc">{a.description}</p>
                <p className="dcs">Posto : {a.machine}</p>
                <p className="dcs">Data : {a.date}</p>
                <p className="dcs">Sentido : {a.direction}</p>
                <p className="dcs" style={this.delayStyle(a.accessStatus)}>Estado : {a.accessStatus}</p>
              </div> 
              </li>;
          } )}
          </ul>
          </div>
          </>
        );
    }
};